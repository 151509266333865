import { Box, Modal } from "@mui/material";
import { Close } from "@mui/icons-material";
import React from "react";
import { Paper, Typography, Divider } from "@mui/material";
import { LocationOn, ShoppingCart, AttachMoney } from "@mui/icons-material";
import { OrderType } from "../../types/types";

interface Address {
  name: string;
  email: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  pinCode: string;
  country: string;
}

interface  OrderSummaryProps {
  item?: OrderType;
  open: boolean;
  handleClose: Function;
}

export const OrderModal: React.FC<OrderSummaryProps> = ({
  item,
  open,
  handleClose,
}) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "max-content",
    minWidth: "40rem",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 4,
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} height={"max"} overflow={"scroll"}>
        <div
          className="absolute right-4 top-4 cursor-pointer "
          onClick={() => handleClose()}
        >
          <Close className="" />
        </div>
        <div className="shadow-md p-4">
          <h5 className="text-xl font-bold mb-2">Order Summary</h5>
          <hr className="mb-2" />
          <p className="mb-4">Order ID: {item?.orderID}</p>

          <h6 className="text-lg font-bold mb-2">Customer Name</h6>
          <p className="mb-4">
            {item?.customer?.firstName} {item?.customer?.lastName}
            <br />
            {item?.customer?.email}
            <br />
            {item?.customer?.countryCode} {item?.customer?.phone}
          </p>

          <hr className="mt-2 mb-2" />

          <h6 className="text-lg font-bold mb-2">Shipping Address</h6>
          <p className="mb-4 capitalize">
            {item?.address?.shipping.name}
            <br />
            {item?.address?.shipping.line1}
            <br />
            {item?.address?.shipping.line2 && `${item?.address?.shipping.line2}, `}
            <br />
            {item?.address?.shipping.city}, {item?.address?.shipping.state} - <br />
            {item?.address?.shipping.pinCode}
            <br />
            {item?.address?.shipping.country}
          </p>

          <hr className="mt-2 mb-2" />

          <h6 className="text-lg font-bold mb-2">Items</h6>
          <div>
            {item?.items &&
              item?.items.map((prod, index) => (
                <div key={index} className="flex justify-between">
                  <p className="capitalize w-3/4 text-wrap">
                    {item?.productDetails[index]?.title}
                  </p>
                  <p>
                    {prod?.quantity} x {prod?.price}
                  </p>
                </div>
              ))}
          </div>

          <hr className="mt-2 mb-2" />

          <h6 className="text-lg font-bold mb-2">Shipping Charges</h6>
          <div className="flex justify-between">
            <p className="capitalize">Charge</p>
            <p>
              {item?.totalOrderDelivery}
            </p>
          </div>

          <hr className="mt-2 mb-2" />

          <div className="flex justify-between py-2">
            <h2 className="font-bold text-lg">Total</h2>
            <h6>
              {item && (item?.totalOrderAmount) + (item?.totalOrderDelivery)}
            </h6>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
