import { Box, Modal } from "@mui/material";
import { Close } from "@mui/icons-material";
import "./productModal.css";
import { ProductType, FiltersType } from "../../types/types";
import {
  InputFieldV1,
  MultiFileInputField,
  TextEditor,
} from "../atoms/inputField";
import { useFormik } from "formik";
import snackbar from "../../api/snackbar/useSnackbar";
import {
  useCreateProductMutation,
  useUpdateProductMutation,
} from "../../api/product";
import {
  ProductSchema,
  UpdateProductSchema,
} from "../../validationSchema/productSchema";
import { SelectBoxV1 } from "../atoms/selectBox";
import { ButtonV1 } from "../atoms/button";
import { useGetBrandQuery } from "../../api/filters/brand";
import { useGetCategoryQuery } from "../../api/filters/category";
import { useGetManufacturerQuery } from "../../api/filters/manufacturer";
import { useGetTypesQuery } from "../../api/filters/types";
import { useEffect } from "react";
// import MultipleImageUpload from "./multipleImageUpload";

interface props {
  item?: ProductType;
  open: boolean;
  handleClose: Function;
}

function calculateDiscountPercentage(costPrice = 0, sellingPrice = 0) {
  if (sellingPrice >= costPrice) {
    return 0;
  }
  let discountAmount = costPrice - sellingPrice;
  let discountPercentage = (discountAmount / costPrice) * 100;
  return discountPercentage;
}

export function AddProductModal({ open, handleClose }: props) {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 4,
  };

  const [create, { isLoading: createIsLoading, isSuccess: createSuccess }] =
    useCreateProductMutation({});

  const Formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      imgUrl: "",
      inventory: 0,
      imgGallery: [],
      price: {
        discount: undefined,
        marketPrice: undefined,
        sellingPrice: undefined,
      },
      productInfo: {
        brandId: "",
        typeId: "",
        dischargeCurrent: "",
        chargeCurrent: "",
        bmsType: "",
        enclosure: "",
        // packaging: "",
        // supportActivations: "",
        // weight: "",
        // dimension: "",
      },
      // productType: {
      //   categoryId: "",
      //   condition: "",
      //   manufacturerId: "",
      // },
      attributes: {
        title: "",
        keywords: "",
        description: "",
      },
    },

    enableReinitialize: false,
    validationSchema: ProductSchema,
    onSubmit: (values) => {
      create({ ...values })
        .unwrap()
        .then((res: { message: string }) => {
          console.log(res);
          snackbar.success(res.message);
          handleClose();
        })
        .catch((error: { data: { message: string } }) => {
          console.log(error);
          snackbar.error(error?.data?.message);
        });
    },
  });

  useEffect(() => {
    const sellingPrice = Formik.values.price.sellingPrice;
    const marketPrice = Formik.values.price.marketPrice;
    const discount = calculateDiscountPercentage(
      marketPrice,
      sellingPrice
    ).toFixed(1);
    Formik.setFieldValue("price.discount", discount);
  }, [Formik.values.price.sellingPrice, Formik.values.price.marketPrice]);

  const { data: BrandData, isLoading: BrandLoading } = useGetBrandQuery({
    pageSize: 1000,
    pageNo: 1,
    sortType: "name",
    sortOrder: 1,
  });
  const { data: CategoryData, isLoading: CategoryLoading } =
    useGetCategoryQuery({
      pageSize: 1000,
      pageNo: 1,
      sortType: "name",
      sortOrder: 1,
    });
  const { data: ManufacturerData, isLoading: ManufacturerLoading } =
    useGetManufacturerQuery({
      pageSize: 10000,
      pageNo: 1,
      sortType: "name",
      sortOrder: 1,
    });

  const { data: TypesData, isLoading: TypesLoading } = useGetTypesQuery({
    pageSize: 10000,
    pageNo: 1,
    sortType: "name",
    sortOrder: 1,
  });

  console.log(Formik.errors);
  console.log(Formik.values);

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} height={"90vh"} overflow={"scroll"}>
        <div
          className="absolute right-4 top-4 cursor-pointer"
          onClick={() => handleClose()}
        >
          <Close className="" />
        </div>
        <h1 className="font-semibold text-xl py-6 pb-2 px-8 mb-4 border-b">
          Create Product
        </h1>
        {CategoryLoading ||
        ManufacturerLoading ||
        BrandLoading ||
        TypesLoading ? (
          <h1 className="text-lg font-bold w-full text-center">Loading..</h1>
        ) : (
          <form
            className="w-full flex flex-wrap p-8 pb-0 pt-0 justify-between"
            noValidate
            onSubmit={Formik.handleSubmit}
          >
            <InputFieldV1
              name="title"
              lable="Name"
              type="text"
              placeholder="Name of Plant"
              value={Formik.values.title}
              errors={Formik.errors.title}
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            />
            <TextEditor
              name="description"
              lable="Description *"
              type="text"
              placeholder="Description"
              value={Formik.values.description}
              formik={Formik}
              handleChange={Formik.handleChange}
            />
            <MultiFileInputField
              name="imgGallery"
              lable="Main image"
              type="file"
              accept="image/*"
              formik={Formik}
              image={Formik.values.imgGallery}
            />
            <InputFieldV1
              width="half"
              name="inventory"
              lable="Inventory/Stock"
              type="number"
              placeholder="Inventory"
              value={Formik.values.inventory}
              errors={Formik.errors.inventory}
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            />
            <h1 className="w-full font-bold text-lg mb-4 mt-2"> Price </h1>
            <InputFieldV1
              width="half"
              name="price.discount"
              lable="Discount(%)"
              type="number"
              placeholder="Discount(%)"
              active={true}
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
              value={Formik.values.price && Formik.values.price.discount}
              errors={Formik.errors.price && Formik.errors.price.discount}
            />
            <InputFieldV1
              width="half"
              name="price.marketPrice"
              lable="MarketPrice"
              type="number"
              placeholder="MarketPrice"
              value={Formik.values.price && Formik.values.price.marketPrice}
              errors={Formik.errors.price && Formik.errors.price.marketPrice}
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            />
            <InputFieldV1
              width="half"
              name="price.sellingPrice"
              lable="SellingPrice"
              type="number"
              placeholder="SellingPrice"
              value={Formik.values.price && Formik.values.price.sellingPrice}
              errors={Formik.errors.price && Formik.errors.price.sellingPrice}
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            />
            <h1 className="w-full font-bold text-lg mb-4 mt-2">Product Info</h1>
            <SelectBoxV1
              formik={Formik}
              lable="Brand"
              name="productInfo.brandId"
              options={BrandData?.data?.data.map((item: FiltersType) => ({
                id: item._id,
                title: item.name,
              }))}
              value={
                Formik.values.productInfo && Formik.values.productInfo.brandId
              }
              errors={
                Formik.errors.productInfo && Formik.errors.productInfo.brandId
              }
            />
            <SelectBoxV1
              formik={Formik}
              lable="Type"
              name="productInfo.typeId"
              options={TypesData?.data?.data.map((item: FiltersType) => ({
                id: item._id,
                title: item.name,
              }))}
              value={
                Formik.values.productInfo && Formik.values.productInfo.typeId
              }
              errors={
                Formik.errors.productInfo && Formik.errors.productInfo.typeId
              }
            />
            <InputFieldV1
              width="half"
              name="productInfo.dischargeCurrent"
              lable="Size"
              type="text"
              placeholder="Size"
              value={
                Formik.values.productInfo &&
                Formik.values.productInfo.dischargeCurrent
              }
              errors={
                Formik.errors.productInfo &&
                Formik.errors.productInfo.dischargeCurrent
              }
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            />
            <InputFieldV1
              width="half"
              name="productInfo.chargeCurrent"
              lable="Shelf Life (days)"
              type="text"
              placeholder="Shelf LIfe"
              value={
                Formik.values.productInfo &&
                Formik.values.productInfo.chargeCurrent
              }
              errors={
                Formik.errors.productInfo &&
                Formik.errors.productInfo.chargeCurrent
              }
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            />
            <InputFieldV1
              width="half"
              name="productInfo.bmsType"
              lable="Container Type"
              type="text"
              placeholder="Container Type"
              value={
                Formik.values.productInfo && Formik.values.productInfo.bmsType
              }
              errors={
                Formik.errors.productInfo && Formik.errors.productInfo.bmsType
              }
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            />
            <InputFieldV1
              width="half"
              name="productInfo.enclosure"
              lable="Net Quantity"
              type="text"
              placeholder="Net Quantity"
              value={
                Formik.values.productInfo && Formik.values.productInfo.enclosure
              }
              errors={
                Formik.errors.productInfo && Formik.errors.productInfo.enclosure
              }
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            />
            {/* <InputFieldV1
              width="half"
              name="productInfo.packaging"
              lable="Packaging"
              type="text"
              placeholder="Packaging"
              value={
                Formik.values.productInfo && Formik.values.productInfo.packaging
              }
              errors={
                Formik.errors.productInfo && Formik.errors.productInfo.packaging
              }
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            /> */}
            {/* <InputFieldV1
              width="half"
              name="productInfo.supportActivations"
              lable="SupportActivations"
              type="text"
              placeholder="SupportActivations"
              value={
                Formik.values.productInfo &&
                Formik.values.productInfo.supportActivations
              }
              errors={
                Formik.errors.productInfo &&
                Formik.errors.productInfo.supportActivations
              }
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            /> */}
            {/* <InputFieldV1
              width="half"
              name="productInfo.weight"
              lable="Weight"
              type="text"
              placeholder="Weight"
              value={
                Formik.values.productInfo && Formik.values.productInfo.weight
              }
              errors={
                Formik.errors.productInfo && Formik.errors.productInfo.weight
              }
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            /> */}
            {/* <InputFieldV1
              width="half"
              name="productInfo.dimension"
              lable="Dimension"
              type="text"
              placeholder="Dimension"
              value={
                Formik.values.productInfo && Formik.values.productInfo.dimension
              }
              errors={
                Formik.errors.productInfo && Formik.errors.productInfo.dimension
              }
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            /> */}
            {/* <h1 className="w-full font-bold text-lg mb-4 mt-2">Product Type</h1> */}
            {/* <SelectBoxV1
              formik={Formik}
              lable="Category"
              name="productType.categoryId"
              options={CategoryData?.data?.data.map((item: FiltersType) => ({
                id: item._id,
                title: item.name,
              }))}
              value={
                Formik.values.productType &&
                Formik.values.productType.categoryId
              }
              errors={
                Formik.errors.productType &&
                Formik.errors.productType.categoryId
              }
            /> */}
            {/* <SelectBoxV1
              formik={Formik}
              lable="Condition"
              name="productType.condition"
              options={[
                { id: "old", title: "Old" },
                { id: "new", title: "New" },
              ]}
              value={
                Formik.values.productType && Formik.values.productType.condition
              }
              errors={
                Formik.errors.productType && Formik.errors.productType.condition
              }
            /> */}
            {/* <SelectBoxV1
              formik={Formik}
              lable="Manufacturer"
              name="productType.manufacturerId"
              options={ManufacturerData?.data?.data.map(
                (item: FiltersType) => ({
                  id: item._id,
                  title: item.name,
                })
              )}
              value={
                Formik.values.productType &&
                Formik.values.productType.manufacturerId
              }
              errors={
                Formik.errors.productType &&
                Formik.errors.productType.manufacturerId
              }
            /> */}
            <h1 className="w-full font-bold text-lg mb-4 mt-2">
              SEO Attributes
            </h1>
            <InputFieldV1
              width="half"
              name="attributes.title"
              lable="Title"
              type="text"
              placeholder="Title"
              value={Formik.values.attributes && Formik.values.attributes.title}
              errors={
                Formik.errors.attributes && Formik.errors.attributes.title
              }
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            />
            <InputFieldV1
              width="half"
              name="attributes.keywords"
              lable="Keyword"
              type="text"
              placeholder="Keyword"
              value={
                Formik.values.attributes && Formik.values.attributes.keywords
              }
              errors={
                Formik.errors.attributes && Formik.errors.attributes.keywords
              }
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            />
            <InputFieldV1
              width="half"
              name="attributes.description"
              lable="Description"
              type="text"
              placeholder="Description"
              value={
                Formik.values.attributes && Formik.values.attributes.description
              }
              errors={
                Formik.errors.attributes && Formik.errors.attributes.description
              }
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            />
            <div className="w-full flex justify-end p-8 pt-0">
              <div className="w-max">
                <ButtonV1 color="blue" type="submit">
                  Save
                </ButtonV1>
              </div>
            </div>
          </form>
        )}
      </Box>
    </Modal>
  );
}

export function EditProductModal({ item, open, handleClose }: props) {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 4,
  };

  const [update, { isLoading: updateIsLoading, isSuccess: updateSuccess }] =
    useUpdateProductMutation({});

  const Formik = useFormik({
    initialValues: {
      title: item?.title ?? "",
      description: item?.description ?? "",
      imgUrl: item?.imgGallery[0] ?? "",
      inventory: item?.inventory,
      imgGallery: item?.imgGallery ?? [],
      price: {
        discount: item?.price?.discount ?? 0,
        marketPrice: item?.price?.marketPrice ?? 0,
        sellingPrice: item?.price?.sellingPrice ?? 0,
      },
      productInfo: {
        brandId: item?.productInfo?.brandId ?? "",
        typeId: item?.productInfo?.typeId ?? "",
        dischargeCurrent: item?.productInfo?.dischargeCurrent ?? "",
        chargeCurrent: item?.productInfo?.chargeCurrent ?? "",
        bmsType: item?.productInfo?.bmsType ?? "",
        enclosure: item?.productInfo?.enclosure ?? "",
        packaging: item?.productInfo?.packaging ?? "",
        supportActivations: item?.productInfo?.supportActivations ?? "",
        weight: item?.productInfo?.weight ?? "",
        dimension: item?.productInfo?.dimension ?? "",
      },
      productType: {
        categoryId: item?.productType?.categoryId ?? "",
        condition: item?.productType?.condition ?? "",
        manufacturerId: item?.productType?.manufacturerId ?? "",
      },
      attributes: {
        title: item?.attributes?.title ?? "",
        keywords: item?.attributes?.keywords ?? "",
        description: item?.attributes?.description ?? "",
      },
    },
    enableReinitialize: true,
    validationSchema: UpdateProductSchema,
    onSubmit: (values) => {
      update({ id: item?._id, body: values })
        .unwrap()
        .then((res: { message: string }) => {
          console.log(res);
          snackbar.success(res.message);
          handleClose();
        })
        .catch((error: { data: { message: string } }) => {
          console.log(error);
          snackbar.error(error?.data?.message);
        });
    },
  });

  useEffect(() => {
    const sellingPrice = Formik.values.price.sellingPrice;
    const marketPrice = Formik.values.price.marketPrice;
    const discount = calculateDiscountPercentage(
      marketPrice,
      sellingPrice
    ).toFixed(1);
    Formik.setFieldValue("price.discount", discount);
  }, [Formik.values.price.sellingPrice, Formik.values.price.marketPrice]);

  const { data: BrandData, isLoading: BrandLoading } = useGetBrandQuery({
    pageSize: 1000,
    pageNo: 1,
    sortType: "name",
    sortOrder: 1,
  });
  const { data: CategoryData, isLoading: CategoryLoading } =
    useGetCategoryQuery({
      pageSize: 1000,
      pageNo: 1,
      sortType: "name",
      sortOrder: 1,
    });
  const { data: ManufacturerData, isLoading: ManufacturerLoading } =
    useGetManufacturerQuery({
      pageSize: 10000,
      pageNo: 1,
      sortType: "name",
      sortOrder: 1,
    });

  const { data: TypesData, isLoading: TypesLoading } = useGetTypesQuery({
    pageSize: 10000,
    pageNo: 1,
    sortType: "name",
    sortOrder: 1,
  });

  console.log("error>>>", Formik.errors);
  console.log("values>>>", Formik.values);

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} height={"90vh"} overflow={"scroll"}>
        <div
          className="absolute right-4 top-4 cursor-pointer"
          onClick={() => handleClose()}
        >
          <Close className="" />
        </div>
        <h1 className="font-semibold text-xl py-6 pb-2 px-8 mb-4 border-b">
          Update Product
        </h1>
        {CategoryLoading ||
        ManufacturerLoading ||
        BrandLoading ||
        TypesLoading ? (
          <h1 className="text-lg font-bold w-full text-center">Loading..</h1>
        ) : (
          <form
            className="w-full flex flex-wrap p-8 pb-0 pt-0 justify-between"
            noValidate
            onSubmit={Formik.handleSubmit}
          >
            <InputFieldV1
              width="half"
              name="title"
              lable="Title"
              type="text"
              placeholder="Title"
              value={Formik.values.title}
              errors={Formik.errors.title}
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            />
            <TextEditor
              name="description"
              lable="Description"
              type="text"
              placeholder="Description"
              value={Formik.values.description}
              formik={Formik}
              handleChange={Formik.handleChange}
            />
            <MultiFileInputField
              name="imgGallery"
              lable="Thumbs"
              type="file"
              accept="image/*"
              formik={Formik}
              image={Formik.values.imgGallery}
            />
            <InputFieldV1
              width="half"
              name="inventory"
              lable="Inventory"
              type="number"
              placeholder="Inventory/Stock"
              value={Formik.values.inventory}
              errors={Formik.errors.inventory}
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            />
            <h1 className="w-full font-bold text-lg mb-4 mt-2"> Price </h1>
            <InputFieldV1
              width="half"
              name="price.discount"
              lable="Discount(%)"
              type="number"
              placeholder="Discount(%)"
              active={true}
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
              value={Formik.values.price && Formik.values.price.discount}
              errors={Formik.errors.price && Formik.errors.price.discount}
            />
            <InputFieldV1
              width="half"
              name="price.marketPrice"
              lable="MarketPrice"
              type="number"
              placeholder="MarketPrice"
              value={Formik.values.price && Formik.values.price.marketPrice}
              errors={Formik.errors.price && Formik.errors.price.marketPrice}
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            />
            <InputFieldV1
              width="half"
              name="price.sellingPrice"
              lable="SellingPrice"
              type="number"
              placeholder="SellingPrice"
              value={Formik.values.price && Formik.values.price.sellingPrice}
              errors={Formik.errors.price && Formik.errors.price.sellingPrice}
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            />
            <h1 className="w-full font-bold text-lg mb-4 mt-2">Product Info</h1>
            <SelectBoxV1
              formik={Formik}
              lable="Brand"
              name="productInfo.brandId"
              options={BrandData?.data?.data.map((item: FiltersType) => ({
                id: item._id,
                title: item.name,
              }))}
              value={
                Formik.values.productInfo && Formik.values.productInfo.brandId
              }
              errors={
                Formik.errors.productInfo && Formik.errors.productInfo.brandId
              }
            />
            <SelectBoxV1
              formik={Formik}
              lable="Type"
              name="productInfo.typeId"
              options={TypesData?.data?.data.map((item: FiltersType) => ({
                id: item._id,
                title: item.name,
              }))}
              value={
                Formik.values.productInfo && Formik.values.productInfo.typeId
              }
              errors={
                Formik.errors.productInfo && Formik.errors.productInfo.typeId
              }
            />
            <InputFieldV1
              width="half"
              name="productInfo.dischargeCurrent"
              lable="Size"
              type="text"
              placeholder="Size"
              value={
                Formik.values.productInfo &&
                Formik.values.productInfo.dischargeCurrent
              }
              errors={
                Formik.errors.productInfo &&
                Formik.errors.productInfo.dischargeCurrent
              }
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            />
            <InputFieldV1
              width="half"
              name="productInfo.chargeCurrent"
              lable="Shelf Life (days)"
              type="text"
              placeholder="Shelf Life"
              value={
                Formik.values.productInfo &&
                Formik.values.productInfo.chargeCurrent
              }
              errors={
                Formik.errors.productInfo &&
                Formik.errors.productInfo.chargeCurrent
              }
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            />
            <InputFieldV1
              width="half"
              name="productInfo.bmsType"
              lable="Container Type"
              type="text"
              placeholder="Container Type"
              value={
                Formik.values.productInfo && Formik.values.productInfo.bmsType
              }
              errors={
                Formik.errors.productInfo && Formik.errors.productInfo.bmsType
              }
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            />
            <InputFieldV1
              width="half"
              name="productInfo.enclosure"
              lable="Net Quantity"
              type="text"
              placeholder="Net Quantity"
              value={
                Formik.values.productInfo && Formik.values.productInfo.enclosure
              }
              errors={
                Formik.errors.productInfo && Formik.errors.productInfo.enclosure
              }
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            />
            {/* <InputFieldV1
              width="half"
              name="productInfo.packaging"
              lable="Packaging"
              type="text"
              placeholder="Packaging"
              value={
                Formik.values.productInfo && Formik.values.productInfo.packaging
              }
              errors={
                Formik.errors.productInfo && Formik.errors.productInfo.packaging
              }
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            /> */}
            {/* <InputFieldV1
              width="half"
              name="productInfo.supportActivations"
              lable="SupportActivations"
              type="text"
              placeholder="SupportActivations"
              value={
                Formik.values.productInfo &&
                Formik.values.productInfo.supportActivations
              }
              errors={
                Formik.errors.productInfo &&
                Formik.errors.productInfo.supportActivations
              }
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            /> */}
            {/* <InputFieldV1
              width="half"
              name="productInfo.weight"
              lable="Weight"
              type="text"
              placeholder="Weight"
              value={
                Formik.values.productInfo && Formik.values.productInfo.weight
              }
              errors={
                Formik.errors.productInfo && Formik.errors.productInfo.weight
              }
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            /> */}
            {/* <InputFieldV1
              width="half"
              name="productInfo.dimension"
              lable="Dimension"
              type="text"
              placeholder="Dimension"
              value={
                Formik.values.productInfo && Formik.values.productInfo.dimension
              }
              errors={
                Formik.errors.productInfo && Formik.errors.productInfo.dimension
              }
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            /> */}
            {/* <h1 className="w-full font-bold text-lg mb-4 mt-2">Product Type</h1> */}
            {/* <SelectBoxV1
              formik={Formik}
              lable="Category"
              name="productType.categoryId"
              options={CategoryData?.data?.data.map((item: FiltersType) => ({
                id: item._id,
                title: item.name,
              }))}
              value={
                Formik.values.productType &&
                Formik.values.productType.categoryId
              }
              errors={
                Formik.errors.productType &&
                Formik.errors.productType.categoryId
              }
            /> */}
            {/* <SelectBoxV1
              formik={Formik}
              lable="Condition"
              name="productType.condition"
              options={[
                { id: "old", title: "Old" },
                { id: "new", title: "New" },
              ]}
              value={
                Formik.values.productType && Formik.values.productType.condition
              }
              errors={
                Formik.errors.productType && Formik.errors.productType.condition
              }
            /> */}
            {/* <SelectBoxV1
              formik={Formik}
              lable="Manufacturer"
              name="productType.manufacturerId"
              options={ManufacturerData?.data?.data.map(
                (item: FiltersType) => ({
                  id: item._id,
                  title: item.name,
                })
              )}
              value={
                Formik.values.productType &&
                Formik.values.productType.manufacturerId
              }
              errors={
                Formik.errors.productType &&
                Formik.errors.productType.manufacturerId
              }
            /> */}
            <h1 className="w-full font-bold text-lg mb-4 mt-2">
              SEO Attributes
            </h1>
            <InputFieldV1
              width="half"
              name="attributes.title"
              lable="Title"
              type="text"
              placeholder="Title"
              value={Formik.values.attributes && Formik.values.attributes.title}
              errors={
                Formik.errors.attributes && Formik.errors.attributes.title
              }
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            />
            <InputFieldV1
              width="half"
              name="attributes.keywords"
              lable="Keyword"
              type="text"
              placeholder="Keyword"
              value={
                Formik.values.attributes && Formik.values.attributes.keywords
              }
              errors={
                Formik.errors.attributes && Formik.errors.attributes.keywords
              }
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            />
            <InputFieldV1
              width="half"
              name="attributes.description"
              lable="Description"
              type="text"
              placeholder="Description"
              value={
                Formik.values.attributes && Formik.values.attributes.description
              }
              errors={
                Formik.errors.attributes && Formik.errors.attributes.description
              }
              handleChange={Formik.handleChange}
              handleBlur={Formik.handleBlur}
            />
            <div className="w-full flex justify-end p-8 pt-0">
              <div className="w-max">
                <ButtonV1 color="blue" type="submit">
                  Save
                </ButtonV1>
              </div>
            </div>
          </form>
        )}
      </Box>
    </Modal>
  );
}
