import apiSlice from "../features/apiSlice";

const uploadImageApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    uploadImg: builder.mutation({
      query: ({ file }) => {
        return {
          url: `/s3Upload/fileUpload?filename=${file.name}`,
          method: "GET"
        };
      },
    }),
  }),
});

export const { useUploadImgMutation } = uploadImageApiSlice;
