import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiSlice from "../features/apiSlice";
import { removeEmptyKeys } from "../utils/helper";
import { getAuthFromLocal } from "../utils/storage";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    bulkImport: builder.mutation({
      query: (credentials) => ({
        url: "/exportImport/service/import",
        method: "POST",
        body: credentials,
      }),
    }),
    // bulkExport: builder.mutation({
    //   query: () => ({
    //     url: "/exportImport/service/export",
    //     method: "GET",
    //   }),
    // }),
  }),
});

export const { useBulkImportMutation } = authApiSlice;

export const bulkDownloadApiSlice = createApi({
  reducerPath: "bulkDownloadApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://excel-export.bytive.in",
    prepareHeaders: (headers, { getState }) => {
      const data = getAuthFromLocal();
      const token = data?.accessToken;
      token && headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    bulkExport: builder.mutation({
      query: (queries) => ({
        url: "/orders/getCsv",
        method: "GET",
        params: removeEmptyKeys(queries?.query),
        // body: queries?.data,
      }),
    }),
  }),
});

export const { useBulkExportMutation } = bulkDownloadApiSlice;
